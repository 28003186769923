@tailwind base;
@tailwind components;
@tailwind utilities;

@import "~react-image-gallery/styles/css/image-gallery.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1C1917;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container {
  min-width: 100%;
  min-height: 100%;
  top: 0;
  left: 0;
  position: fixed;
  z-index: -1;
}

.video2 {
  min-width: 100%;
  min-height: 100%;
  left: 0;
  vertical-align: middle;
  position: fixed;
  z-index: -1;
}

.videoViewport {
  width: 100%;
  max-height: 100%;
  object-fit: cover;
  position: relative;
}

.videoContainer {
  min-width: 100%;
  aspect-ratio: 16/9;
  max-height: 100%;
  overflow-y: hidden;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  position: fixed;
  z-index: 10;
}

.mapContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 100%;
  min-height: 100%;
  position: fixed;
  left: 0;
}

.video {
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
}

.tooltip {
  z-index: 100;
  position: absolute;
  transform: translate3d(-50%, -50%, 0);
}


.PosterModal {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  transform: translate(-50%, -50%);

  border: 0;
  height: 100%;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
}

.PosterOverlay {
  /*
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0);
  */
  background-color: rgba(0, 0, 0, 0);
  min-width: 100%;
  aspect-ratio: 16/9;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  position: fixed;
  z-index: 99;
}

.GalleryImage {
  object-fit: cover;
  cursor: pointer;
  padding-right: 0.5rem;
  padding-bottom: 0.5rem;
  overflow: hidden;
  transition: transform .2s;
}

.GalleryImage:hover {
  filter: brightness(1.05);
}

.GalleryVideo {
  object-fit: cover;
  cursor: pointer;
  padding-right: 0.5rem;
  padding-bottom: 0.5rem;
  overflow: hidden;
  border-radius: 1px;
  -webkit-border-radius: 1px;
  transition: transform .2s;
}

.GalleryVideo:hover {
  filter: brightness(1.05);
}

.GalleryBaseLayout {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: 600px;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For IE, Edge and Firefox */
.scrollbar-hide {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.close {
  position: absolute;
  width: 50%;
  height: 50%;
  opacity: 1;
}

.close:hover {
  opacity: 1;
}

.close:before, .close:after {
  position: absolute;
  content: '';
  height: 100%;
  width: 5px;
  background-color: rgb(203 213 225);
}

.close:before {
  transform: rotate(35deg);
}

.close:after {
  transform: rotate(-35deg);
}